<template>
<div id="app">
    <top-navbar id="top-navbar"></top-navbar>
    <div id="start-menu" v-show="start_menu" class="start-menu">
    </div>
    <div class="screen" id="screen">
        <div 
            v-for="window in windows" 
            :key="window.key" 
            :aria-label="window.displayName"
        >
            <component
                :is="window.windowComponent" 
                :nameOfWindow="window.windowId" 
                :content_padding_bottom="window.windowContentPadding['bottom']"
                :content_padding_left="window.windowContentPadding['left']"
                :content_padding_right="window.windowContentPadding['right']"
                :content_padding_top="window.windowContentPadding['top']"
                :id="window.windowId" 
                :style="{position: window.position, left: window.positionX, top: window.positionY}" 
                v-if="windowCheck(window.windowId)"
            >
                <component 
                    :is="window.windowContent"
                    slot="content"
                >
                </component>
            </component>
        </div>
        <app-grid></app-grid>
    </div>
    <navbar @openMenu="start_menu = $event;" :show_menu="start_menu" id="navbar" />
</div>
</template>

<script>
/*------------------------------------------------------------*\
    Import Components, you can change the path for different
    component themes (blueprint, windows, macos)
\*------------------------------------------------------------*/
/*import Navbar from './components/blueprint/Navbar'*/
import Navbar from './components/windows/Navbar'
// import TopNavbar from './components/macos/TopNavbar.vue'
import Window from './components/template/Window'
import AppGrid from './components/AppGrid'
import Placeholder from './components/views/Placeholder'
import Photos from './components/views/Photos'
import OSWindow from './components/template/OSWindow'
import Blueprint from './components/views/Blueprint'
import Windows from './components/views/Windows.vue'
import MacOS from './components/views/MacOS.vue'
export default {
    name: 'App',
    data: function () {
        return {
            windows: this.$store.getters.getWindows,
            windowComponents: {},
            start_menu: 0
        }
    },
    components: {
        Window,
        Navbar,
        AppGrid,
        Placeholder,
        Photos,
        OSWindow,
        Blueprint,
        Windows,
        MacOS
        // TopNavbar,
    },
    computed: {
        style() {
            return {
                '--fullscreen': this.$store.getters.getFullscreenWindowHeight
            };
        }
    },
    mounted() {
        /*-------------------------------------------------*\
            This fixes height problems for mobile devices 

            Code is detecting height of navbar and setting
            respective heights of screen
        \*-------------------------------------------------*/

        let navbar = document.getElementById('navbar')
        let topnavbar = document.getElementById('top-navbar')
        let topNavbarHeight = topnavbar.clientHeight
        let navbarHeight = navbar.clientHeight

        document.getElementById('screen').style.height = window.innerHeight - navbarHeight - topNavbarHeight + "px";

        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });

        function resetHeight() {
            document.body.style.height = window.innerHeight + "px";
            document.html.style.height = window.innerHeight + "px";
        }
        window.addEventListener("resize", resetHeight);
        this.$store.commit('setFullscreenWindowHeight', window.innerHeight - navbarHeight - topNavbarHeight + "px");

      const elem = document.getElementById('start-menu');
      const btn = document.getElementById('btn-start-windows');

      // Настраиваем обработку кликов
      document.addEventListener('click', (e) => {
        if (/*!elem.contains(e.target)*/elem != e.target && !btn.contains(e.target)) {
          // Клик произошёл вне элемента
          if (this.start_menu == 1) {
            this.start_menu = 0;
          }
        }
      });
    },
    methods: {
        openWindow(windowId) {
            const payload = {
                'windowState': 'open',
                'windowID': windowId
            }
            this.$store.commit('setWindowState', payload)
        },

        windowCheck(windowId) {
            if (this.$store.getters.getWindowById(windowId).windowState == 'open' || this.$store.getters.getWindowById(windowId).windowState == 'minimize') {
                return true
            }
        },
    },
}
</script>

<style>
/*---------------------------------------------------------------------------*\
    CSS Imports
    Change 'blueprint' to 'windows' or 'macos' to use windows or macos theme
\*---------------------------------------------------------------------------*/
@import './assets/css/utils/normalize.css';
/*@import './assets/css/blueprint/app.css';
@import './assets/css/blueprint/window.css';
@import './assets/css/blueprint/appgrid.css';*/
@import './assets/css/windows/app.css';
@import './assets/css/windows/window.css';
@import './assets/css/windows/appgrid.css';

/*-------------------------------------------*\
    Utilities
\*-------------------------------------------*/

html {
    overflow: hidden;
}

#app {
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    flex-direction: column;
}

.screen {
    width: 100%;
    position: relative;
    z-index: 999;
}

/*-------------------------------------------*\
    Fullscreen
\*-------------------------------------------*/

.fullscreen {
    left: 0 !important;
    position: relative;
    display: block;
    top: 0 !important;
    right: 0 !important;
}

.start-menu {
  position: fixed;
  width: 400px;
  height: 70vh;
  background-color: #ffff;
  bottom: 36px;
  padding: 10px;
  z-index: 1000;
  box-sizing: border-box;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
</style>
