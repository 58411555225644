<template>
<nav class="navbar-container">
    <div @click="changeShowMenu()" class="btn-start-windows" id="btn-start-windows">Пуск</div>
    <div 
        v-for="window in this.activeWindows" 
        :key="window.key"
    >
        <button 
            v-if="window.windowState == 'minimize' || ($store.getters.getActiveWindow!==window.windowId && (window.windowState=='open' || window.windowState=='minimize'))" v-on:click="openWindow(window.windowId)"
            class="navbar-item open">
                {{window.displayName}}
        </button>
        <button 
            v-if="$store.getters.getActiveWindow==window.windowId && window.windowState != 'minimize'"
            v-on:click="openWindow(window.windowId)" 
            class="navbar-item-depressed">
                {{window.displayName}}
        </button>
    </div>
    <div class="spacer"></div>
    <div alt="time" class="time">
        <!-- <img src="../assets/speakers.png" class="icon-image"/> -->
        <time>
            {{time}}
        </time>
    </div>
</nav>
</template>

<style scoped>
/*-------------------------------------------*\
    Container
\*-------------------------------------------*/

.navbar-container {
    width: 100%;
    height: 35px;
    background: rgb(192, 192, 192);
    border-top: solid rgb(250, 250, 250) 2px;
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
}

/*-------------------------------------------*\
    Navbar Items
\*-------------------------------------------*/

.navbar-item {
    width: 100px;
    height: 23px;
    border-radius: 10px;
    margin-left: 2px;
    margin-right: 2px;
    box-shadow: 1.5px 1.5px black;
    border-top: solid rgb(250, 250, 250) 1.5px;
    border-left: solid rgb(250, 250, 250) 1.5px;
    border-bottom: solid rgb(90, 90, 90) 1.5px;
    border-right: solid rgb(90, 90, 90) 1.5px;
    background: rgb(192, 192, 192);
    border-radius: 0.5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: bold;
    font-size: 0.7rem;
}

.navbar-item:active {
    background: rgb(192, 192, 192);
    box-shadow: none;
    border-top: solid rgb(0, 0, 0) 1.5px;
    border-left: solid rgb(0, 0, 0) 1.5px;
    border-bottom: solid rgb(250, 250, 250) 1.5px;
    border-right: solid rgb(250, 250, 250) 1.5px;
}

.navbar-item:hover {
    cursor: pointer;
}

.navbar-item-depressed {
    width: 100px;
    height: 23px;
    border-radius: 10px;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 0.5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: bold;
    font-size: 0.7rem;
    background: rgb(192, 192, 192);
    box-shadow: none;
    background: repeating-conic-gradient(rgb(189, 190, 189) 0% 25%, rgb(255, 255, 255) 0% 50%) 50% / 2px 2px;
    border-top: solid rgb(0, 0, 0) 1.5px;
    border-left: solid rgb(0, 0, 0) 1.5px;
    border-bottom: solid rgb(250, 250, 250) 1.5px;
    border-right: solid rgb(250, 250, 250) 1.5px;
}

/*-------------------------------------------*\
    Utilities
\*-------------------------------------------*/

.spacer {
    flex-grow: 1;
}

.time {
    width: 75px;
    margin: 5px;
    height: 25px;
    background: rgb(192, 192, 192);
    border-right: solid rgb(250, 250, 250) 1.5px;
    border-bottom: solid rgb(250, 250, 250) 1.5px;
    border-top: solid rgb(90, 90, 90) 1.5px;
    border-left: solid rgb(90, 90, 90) 1.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-size: 0.6em;
}

.btn-start-windows {
  height: 35px;
  line-height: 35px;
  background-color: rgb(81, 171, 63);
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 0 20px;
  font-weight: 700;
  color: #ffff;
  cursor: pointer;
  margin-right: 6px;
  box-shadow: inset 0px 0px 7px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
}

/*-------------------------------------------*\
    CSS Normalisation 
\*-------------------------------------------*/

button {
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    outline: inherit;
}
</style>

<script>
import moment from 'moment'
export default {
    name: 'Navbar',
    props: {
      show_menu: Number
    },
    data: function () {
        return {
            activeWindows: this.$store.getters.getActiveWindows,

            // date time for moment.js
            time: '',
            date: ''
        }
    },
    beforeMount() {
        setInterval(() => {
            this.time = moment().format('HH:mm')
        }, 1000)
        setInterval(() => {
            this.date = moment().format('ddd DD MMMM')
        }, 1000)
    },
    methods: {
        openWindow(windowId) {
            const payload = {
                'windowState': 'open',
                'windowID': windowId
            }
            this.$store.commit('setWindowState', payload)
        },
      changeShowMenu() {
          if (this.show_menu == 1) {
            this.$emit('openMenu', 0)
          } else {
            this.$emit('openMenu', 1)
          }
      }
    }
}
</script>
